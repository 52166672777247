
            @import 'src/styles/mixins.scss';
        
@import '@styles/sassVariables';

.button {
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    min-width: 300px;
    color: white;
    background: $antivirus-choice-blue;

    &:before {
        content: "";
        background-color: rgba(255, 255, 255, 0.5);
        height: 100%;
        width: 3em;
        display: block;
        position: absolute;
        top: 0;
        left: -4.5em;
        transform: skewX(-45deg) translateX(-130%);
        transition: none;
    }

    &:hover {
        filter: brightness(90%);
        &:before {
            transform: skewX(-45deg) translateX(32em);
            transition: all 0.3s ease-in-out;
        }
    }
}
.text-black{
    color: black;
}
.text-white{
    color: white;
}
.gray{
    color: $antivirus-choice-gray-dark
}
.blue{
   color: rgb(39, 135, 225)
}


.bg-bottom-gradient{ position: relative;
    &::before{
        content : "";
        position: absolute;
        z-index: -1;
        left: 50%;
        top: 50%;
        height: 100%;
        width: 100vw;
        transform: translate(-50%, -50%);
        background: linear-gradient(
            to right,
            #2074c1 0%,
            #134e83 100%
        );
    }
    
}