$antivirus-choice-gray: #c6c6c6;
$antivirus-choice-gray-bg: #fafafa;
$antivirus-choice-gray-dark: #b3b3b3;
$antivirus-choice-gray-light: #ececec;
$antivirus-choice-orange: #ff500e;
$antivirus-choice-orange-dark: #ff700e;
$antivirus-choice-orange-light: #ff940d;
$antivirus-choice-orange-popup: #f7951e;
$antivirus-choice-blue: #5dbbfe;
$antivirus-choice-blue-dark: #1b4874;
$antivirus-choice-yellow: #fff9e7;
$antivirus-choice-green: #4cbf47;
$antivirus-choice-red: #f85650;
$antivirus-choice-red-light: #fff5f3;
$antivirus-choice-cyan: #37c8e3;

:export{
    gray: $antivirus-choice-gray;
    grayBg: $antivirus-choice-gray-bg;
    graydark:$antivirus-choice-gray-dark;
    grayLight:$antivirus-choice-gray-light;
    orange:$antivirus-choice-orange;
    orangeDark:$antivirus-choice-orange-dark;
    orangeLight:$antivirus-choice-orange-light;
    orangePopup:$antivirus-choice-orange-popup;
    Blue:$antivirus-choice-blue;
    blueDark:$antivirus-choice-blue-dark;
    yellow:$antivirus-choice-yellow;
    green:$antivirus-choice-green;
    red:$antivirus-choice-red;
    redLight:$antivirus-choice-red-light;
    cyan:$antivirus-choice-cyan
}