
            @import 'src/styles/mixins.scss';
        

@import '@styles/sassVariables';
.header{
    color: white;
}
.offer{
    background-color: white;
    transition: all 350ms ease-in-out;
    box-shadow: 0 2px 74px 0 rgba(0, 0, 0, 0.08);
}
.button {
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    color: white;
    background: linear-gradient(
        to right,
        $antivirus-choice-orange-light 0%,
        $antivirus-choice-orange-dark 100%,
    );
    &:before {
        content: "";
        background-color: rgba(255, 255, 255, 0.5);
        height: 100%;
        width: 3em;
        display: block;
        position: absolute;
        top: 0;
        left: -4.5em;
        transform: skewX(-45deg) translateX(-130%);
        transition: none;
    }

    &:hover {
        filter: brightness(90%);
        &:before {
            transform: skewX(-45deg) translateX(16.5em);
            transition: all 0.3s ease-in-out;
        }
    }
}
.badge{
   position: absolute;
    bottom: 0;
    transform: translate(0%, 50%);
}
@media (min-width: 1024px) {
    .offer:hover{
        transform: scale(1.15);
        z-index: 51;
    }
    .offer:first-child{
        transform: scale(1.25);
        padding-bottom: 2rem;
        z-index: 50;
        order: 2;
        &:hover{
            transform: scale(1.3);
        }
    }
    .offer:nth-child(2){
        order: 1;
    }
    .offer:nth-child(3){
        order: 3;
    }
}

.bg-top-gradient-blue{
    position: relative;
    &::before{
        content : "";
        position: absolute;
        z-index: -1;
        left: 50%;
        top: 50%;
        height:calc(100% + 2px);
        width: 100vw;
        transform: translate(-50%, -50%);
        background: linear-gradient(
            to right,
            #2787e1 0%,
            #185d9a 100%
        );
    }
    
}

.bg-top-gradient-gray{
    position: relative;
    &::before{
        content : "";
        position: absolute;
        z-index: -1;
        left: 50%;
        top: 50%;
        height:calc(100% + 2px);
        width: 100vw;
        transform: translate(-50%, -50%);
        background: #fafafa
    }
}

@media (min-width: 1024px) {
    .bg-top-gradient-gray{
        position: relative;
        &::before{
            content : "";
            position: absolute;
            z-index: -1;
            left: 50%;
            top: 50%;
            height:calc(100% + 2px);
            width: 100vw;
            transform: translate(-50%, -50%);
            background: linear-gradient(
                to top,
                #fafafa 0%,
                #fafafa 50%,
                #ffffff 50%,
                #ffffff 100%
            );
        }
    }
}